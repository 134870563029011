import { invokeApi } from "../../bl_libs/invokeApi";
export const complete_stage = async (id) => {
  const requestObj = {
    path: `api/stage/complete_stage/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
