import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { IconButton, CircularProgress, Chip } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { s3baseUrl } from "../../config/config";
import { ReactVideoDurationPlayer } from "src/components";
import { useSnackbar } from "notistack";
import {
  ProgramRecordingDetail,
  LessonRecordingDetail,
} from "../../DAL/Programmes/Programmes";
import { makeStyles } from "@mui/styles";
import { htmlDecode } from "src/utils/convertHtml";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { Icon } from "@iconify/react";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
function ProgrammRecordingDetail(props) {
  const params = useParams();
  const navigate = useNavigate();
  const { userInfo, adminTimeZone } = useContentSetting();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [recordingData, seRecordingData] = React.useState({});
  const [title, setTitle] = useState("");

  const { handleVideoList } = useContentSetting();
  const [videoProgressData, setVideoProgressData] = useState([]);
  const selectedTabFromProp = location.state?.selectedTabFromProp;

  console.log(selectedTabFromProp, "value in props............");
  const goBack = () => {
    navigate("/previous-route", {
      state: { selectedTabFromProp },
    });

    navigate(`/programmes/${recordingData?.program[0]?._id?.program_slug}`, {
      state: { selectedTabFromProp, title },
    });
  };

  const handleNavigate = () => {
    navigate(-1);
  };
  const handleNavigateLink = (value) => {
    window.open(value, "_blank");
  };
  const handleSetLocal = (value) => {
    console.log(value, "dfklajsd");
    let dataKey = recordingData?._id;
    let _url = recordingData?.video_url;
    let user_id = userInfo?._id;
    let user_Info = localStorage.getItem(`${user_id}`);
    let data = {};
    if (user_Info) {
      data = JSON.parse(user_Info);
    }
    data[dataKey] = {
      url: _url,
      playedSec: value,
    };

    console.log("sdfgjskdlk", JSON.parse(user_Info));
    localStorage.setItem(`${user_id}`, JSON.stringify(data));
  };
  const getRecordingDetail = async () => {
    setIsLoading(true);
    let result = {};
    if (location.pathname.includes("lessons-recordings") == true) {
      result = await LessonRecordingDetail(params.recording_slug);
    } else {
      result = await ProgramRecordingDetail(params.recording_slug);
    }
    if (location.pathname.includes("/lessons-recordings/")) {
      console.log("for lesson recordings");
      localStorage.setItem("video_type", "lesson_recording");
    } else {
      console.log("from program recordings");
      localStorage.setItem("video_type", "replay_library");
    }
    if (result.code === 200) {
      let data = [
        {
          recording_id: result.recording?._id,
          video_duration: result.recording?.video_duration,
          total_video_duration: result.recording?.total_video_duration,
          is_complete: result.recording?.is_complete,
        },
      ];
      setVideoProgressData(data);
      handleVideoList(data);
      localStorage.setItem("video_list", JSON.stringify(data));

      seRecordingData(result.recording);
      setTitle(result.recording.program[0]._id.title);

      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      navigate(`/programmes`);
    }
  };

  useEffect(() => {
    getRecordingDetail();
  }, [params.recording_slug]);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <IconButton className="back-screen-button" onClick={goBack}>
            <ArrowBackIcon />
          </IconButton>
        </div>
      </div>

      <div className="row section-space">
        <div className="col-12">
          <h1 className="programmes-heading">
            {htmlDecode(recordingData.title)}
          </h1>
        </div>
      </div>
      <div className="row ">
        <section className="container top-section-video mb-lg-3">
          <div className="row pt-70 mt-3">
            <div className="col-2"></div>
            <div className="col-8">
              <ReactVideoDurationPlayer
                url={recordingData.video_url}
                type={localStorage.getItem("video_type")}
                value={recordingData}
                setVideoProgressData={setVideoProgressData}
                videoProgressData={videoProgressData}
              />
            </div>
            <div className="col-2"></div>
          </div>
        </section>
        {/* <div className="col-12">
          <ReactVideoDurationPlayer
            url={recordingData.video_url}
            type={localStorage.getItem("video_type")}
            value={recordingData}
            setVideoProgressData={setVideoProgressData}
            videoProgressData={videoProgressData}
          />
        </div> */}
        <div className="col-12 text-end">
          {recordingData.is_complete ? (
            // <Icon
            //   icon="mdi:tick-circle"
            //   width={30}
            //   height={30}
            //   color="primary"
            // />
            <></>
          ) : (
            <></>
          )}
        </div>
        {recordingData.audio_recording && (
          <div className="col-12 mt-3 text-center">
            <div>
              <audio
                controlsList="nodownload"
                className="w-100"
                src={s3baseUrl + recordingData.audio_recording}
                controls
              />
            </div>
          </div>
        )}
        <div className="col-12 section-space text-end">
          {recordingData?.button_text && recordingData?.button_link && (
            <Chip
              label={htmlDecode(recordingData?.button_text)}
              color="primary"
              className="me-1"
              variant="outlined"
              onClick={() => handleNavigateLink(recordingData?.button_link)}
            />
          )}
        </div>
        <div className="col-12 section-space">
          <p className="description-style">
            {htmlDecode(recordingData.short_description)}
          </p>
        </div>
      </div>
    </div>
  );
}

export default ProgrammRecordingDetail;
