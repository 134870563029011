import { invokeApi } from "src/bl_libs/invokeApi";

export const gettingStartingDetailApi = async (lesson_slug) => {
  const requestObj = {
    path: `api/getting_started/${lesson_slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
