import React from "react";
import {
  Box,
  Card,
  Link,
  Typography,
  Stack,
  Button,
  Chip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { s3baseUrl } from "../../../config/config";
import { htmlDecode } from "src/utils/convertHtml";

function ProgrammRecording({ lessonSlug, recording, selectedTabFromProp }) {
  const navigate = useNavigate();
  console.log(selectedTabFromProp, "tabbbbbbbbbbbbss");

  const handleClick = () => {
    if (lessonSlug) {
      navigate(`/lessons-recordings/${recording.recording_slug}`, {
        state: { recording, selectedTabFromProp },
      });
    } else {
      navigate(`/recordings/${recording.recording_slug}`, {
        state: recording,
      });
    }
  };
  const handleNavigate = (value) => {
    window.open(value, "_blank");
  };

  return (
    <>
      <Card className="lesson-card">
        <div className="row p-3">
          <div className="col-lg-2 col-md-3 col-sm-12">
            <img
              className="lesson-card-image"
              src={s3baseUrl + recording.recording_image.thumbnail_2}
              alt="name"
              onClick={handleClick}
            />
          </div>
          <div className="col-lg-10 col-md-9 col-sm-12">
            <h3 className="lesson-heading" onClick={handleClick}>
              {htmlDecode(recording.title)}
            </h3>
            <p className="normal-font" onClick={handleClick}>
              {htmlDecode(recording.short_description)}
            </p>
            {recording?.button_text && recording?.button_link && (
              <Chip
                label={htmlDecode(recording?.button_text)}
                color="primary"
                className="me-1"
                variant="outlined"
                onClick={() => handleNavigate(recording?.button_link)}
              />
            )}
          </div>
        </div>
      </Card>
    </>
  );
}

export default ProgrammRecording;
