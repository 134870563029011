import React from "react";
import { Navigate, Outlet } from "react-router-dom";

export default function FullPageLayoute() {
  if (!localStorage.getItem("token")) {
    return <Navigate to="/login"> </Navigate>;
  }

  return (
    <div>
      <Outlet />
    </div>
  );
}
