import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Link,
} from "@mui/material";

export default function RewardChart() {
  return (
    <div className="container mt-2">
      <div className="row">
        <div className="col-lg-6 col-sm-12 d-flex">
          <h2>Reward Chart</h2>
        </div>
        <div className="card mt-2">
          <table border="1" cellPadding="10" cellSpacing="0">
            <thead>
              <tr>
                <th>Column 1</th>
                <th>Column 2</th>
                <th>Column 3</th>
                <th>Column 4</th>
                <th>Column 5</th>
              </tr>
            </thead>
            <tbody>
              {Array.from({ length: 6 }).map((_, rowIndex) => (
                <tr key={rowIndex}>
                  <td>Row {rowIndex + 1} - Data 1</td>
                  <td>Row {rowIndex + 1} - Data 2</td>
                  <td>Row {rowIndex + 1} - Data 3</td>
                  <td>Row {rowIndex + 1} - Data 4</td>
                  <td>Row {rowIndex + 1} - Data 5</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
