import {
  CircularProgress,
  TextField,
  Card,
  CardContent,
  Box,
  Typography,
  Tooltip,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import PageDescription from "src/components/GeneralComponents/PageDescription";
import EditIcon from "@mui/icons-material/Edit";
import { goalSide, goalLogo } from "src/assets";
import AddGoal from "./AddGoal";
import { complete_goal, get_saved_goals } from "src/DAL/Goals/Goals";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";
import PreviewGoal from "./PreviewGoal";
import CustomConfirmation from "src/components/CustomConfirmation";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import { LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";

import moment from "moment";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
  notchedOutline: {
    borderColor: "#000 !important",
    border: "1px solid",
  },
}));

export default function Goals() {
  const navigate = useNavigate();
  const { contentSettingData, handleGeneralSettingData } = useContentSetting();

  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [editGoal, setEditGoal] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const [goalsList, setGoalsList] = useState([]);
  const [goalsValue, setGoalsValue] = useState();
  let date;
  const handleAgreeDelete = (value) => {
    setOpenDelete(true);
    setGoalsValue(value);
  };
  const handleChangeDate = (newValue) => {
    let todayDate = moment(newValue).format("YYYY-MM-DD");
    let dateType = todayDate.toString();
    //console.log(typeof dateType, "dateType");
    setDate(newValue);
  };

  const getGoalsList = async () => {
    setIsLoading(true);
    const result = await get_saved_goals();
    if (result.code === 200) {
      const formattedGoals = result.goals.map((goal) => ({
        ...goal,
        formattedDate: goal?.createdAt,
      }));

      setIsLoading(false);
      setGoalsList(result?.goals);
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const completeGoal = async (value) => {
    // setGoalsValue(value);
    setOpenDelete(false);

    setIsLoadingButton(true);
    const result = await complete_goal(goalsValue._id);
    if (result.code === 200) {
      setOpenPreview(true);
      setGoalsList((prevRecent) =>
        prevRecent.filter((entry) => entry._id !== goalsValue._id)
      );
      handleGeneralSettingData(result?.goal_completion_coins);

      setIsLoadingButton(false);

      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setOpenDelete(false);

      setIsLoadingButton(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  useEffect(() => {
    getGoalsList();
  }, []);

  const handleOpenGoal = () => {
    setOpenModal(true);
    setEditGoal(false);
  };
  const handleEdit = (value) => {
    console.log(value, "............value...........");
    setGoalsValue(value);
    setEditGoal(true);
    setOpenModal(true);
  };

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="row pt-70" id="assessment-container">
        <div className="col-6 mb-4">
          <PageDescription parameter="goals_heading" else_title="Goals" />
        </div>
        <div className="col-lg-6 text-end mb-2">
          <button className="small-contained-button" onClick={handleOpenGoal}>
            Add Goal
          </button>
          <button
            className="small-contained-button"
            onClick={() => navigate(`/completed-goals`)}
          >
            Completed
          </button>
        </div>
      </div>
      {goalsList && goalsList.length > 0 ? (
        goalsList.map((data, index) => {
          return (
            <div className="d-flex mb-4 row" key={index}>
              <div className="col-1"></div>
              <div className="col-10">
                <Card
                  sx={{
                    backgroundColor: "#ffee00",
                    margin: "auto",
                    position: "relative",
                  }}
                >
                  <Box sx={{ position: "absolute", top: 16, right: 10 }}>
                    {/* <Tooltip title={"Edit Goal"}>
                    <IconButton
                      className="goal-icons"
                      onClick={() => handleEdit(data)}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip> */}
                  </Box>
                  <div className="row">
                    <div
                      className="col-3 mt-4 mt-lg-0 align-items-center d-flex"
                      style={{ width: "20%" }}
                    >
                      <img
                        className="mt-4 mt-lg-0"
                        src={goalSide}
                        alt="Left Aligned"
                        style={{ width: "auto", height: "auto" }}
                      />
                    </div>
                    <div className="col-9 align-items-center">
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <img
                          src={goalLogo}
                          alt="Logo"
                          style={{ width: 150, height: 70 }}
                        />
                      </Box>
                      <div className="col-12 d-flex text-end justify-content-end date-div"></div>

                      <Box className="d-flex">
                        <CardContent>
                          <TextField
                            InputProps={{
                              classes: {
                                notchedOutline: classes.notchedOutline,
                              },
                            }}
                            fullWidth
                            disabled
                            label="Class"
                            className="custom-goal-field"
                            variant="outlined"
                            margin="normal"
                            name="class"
                            value={data.class}
                          />

                          <TextField
                            disabled
                            fullWidth
                            InputProps={{
                              classes: {
                                notchedOutline: classes.notchedOutline,
                              },
                            }}
                            className="custom-goal-field"
                            label="Goal"
                            variant="outlined"
                            margin="normal"
                            name="goal"
                            value={data.goal}
                          />

                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                              disabled
                              fullWidth
                              InputProps={{
                                classes: {
                                  notchedOutline: classes.notchedOutline,
                                },
                              }}
                              label="Target Date*"
                              inputFormat="dd/MM/yyyy"
                              value={moment(data.target_date).format(
                                "DD MMM YYYY"
                              )}
                              onChange={handleChangeDate}
                              renderInput={(params) => (
                                <TextField
                                  className="custom-goal-field"
                                  {...params}
                                  fullWidth
                                  sx={{
                                    marginTop: "10px",
                                    marginBottom: "4px",
                                    "& .MuiOutlinedInput-root": {
                                      "& .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "black",
                                      },
                                      "&:hover .MuiOutlinedInput-notchedOutline":
                                        {
                                          borderColor: "black",
                                        },
                                      "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                        {
                                          borderColor: "black",
                                        },
                                    },
                                    "& .MuiInputLabel-root": {
                                      color: "black",
                                    },
                                    "& .MuiInputLabel-root.Mui-focused": {
                                      color: "black",
                                    },
                                  }}
                                />
                              )}
                            />
                          </LocalizationProvider>

                          <TextField
                            disabled
                            fullWidth
                            className="custom-goal-field"
                            InputProps={{
                              classes: {
                                notchedOutline: classes.notchedOutline,
                              },
                            }}
                            label="Description"
                            variant="outlined"
                            margin="normal"
                            multiline
                            rows={3}
                            name="description"
                            value={data.description}
                          />
                        </CardContent>
                      </Box>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-11 text-end">
                      <button
                        className="small-contained-button-search"
                        style={{ marginBottom: "10px", marginRight: "4px" }}
                        onClick={() => handleEdit(data)}
                      >
                        Edit
                      </button>
                      <button
                        className="small-contained-button-search"
                        style={{ marginBottom: "10px" }}
                        onClick={() => handleAgreeDelete(data)}
                      >
                        {goalsValue &&
                        goalsValue?._id == data?._id &&
                        isLoadingButton
                          ? "Saving..."
                          : "Mark As Complete"}
                      </button>
                    </div>
                    <div className="col-1"></div>
                  </div>
                </Card>
              </div>

              <div className="col-1"></div>
            </div>
          );
        })
      ) : (
        <RecordNotFound title={"No Saved Goals"} />
      )}

      <AddGoal
        openCreateModal={openModal}
        setOpenCreateModal={setOpenModal}
        apiCall={getGoalsList}
        goalsValue={goalsValue}
        editGoal={editGoal}
        setEditGoal={setEditGoal}
      />
      <PreviewGoal
        openCreateModal={openPreview}
        setOpenCreateModal={setOpenPreview}
        goalsValue={goalsValue}
      />
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to complete this Goal ?"}
        handleAgree={completeGoal}
      />
    </div>
  );
}
