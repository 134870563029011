import { invokeApi } from "../../bl_libs/invokeApi";

export const activeTagsApi = async () => {
  const requestObj = {
    path: `api/books_library_tag/active`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const BookListApi = async (page, limit, data) => {
  const requestObj = {
    path: `api/books_library/client_list?page=${page}&limit=${limit}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const bookDetail = async (data) => {
  const requestObj = {
    path: `api/books_library/${data}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
