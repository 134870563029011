import { Box } from "@mui/material";
import Modal from "@mui/material/Modal";
import React from "react";
import { s3baseUrl } from "src/config/config";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "90%",
  maxHeight: "100vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: 1,
  overflow: "auto",
};
export default function PreviewGoal({
  openCreateModal,
  setOpenCreateModal,
  goalsValue,
}) {
  const handleClose = () => {
    setOpenCreateModal(false);
  };
  return (
    <div>
      <Modal
        open={openCreateModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style} className="modal-theme">
          <div
            className="text-center modalIcon col-12 create-post-title-box"
            onClick={() => {
              handleClose();
            }}>
            <span
              style={{
                color: "#fff",
                backgroundColor: "#9f00ff",
                fontWeight: 600,
              }}>
              x
            </span>

            {/* <embed
              class="pdfobject"
              type="application/pdf"
              title="Embedded PDF"
              src={`${s3baseUrl + goalsValue?.certificate_url}#zoom=40`}
              style={{ width: 750, height: 520, overflow: "auto" }}></embed> */}
            <object
              data={`${s3baseUrl + goalsValue?.certificate_url}#zoom=38`}
              type="application/pdf"
              // width="100%"
              // height="100%"
              style={{ width: 750, height: 490, overflow: "auto" }}>
              <p>
                Your web browser doesn't have a PDF plugin. Instead you can{" "}
                <a
                  href={s3baseUrl + goalsValue?.certificate_url}
                  target="_blank">
                  click here to open the PDF file.
                </a>
              </p>
            </object>
            {/* <object
              data={`${s3baseUrl + goalsValue?.certificate_url}#zoom=40`}
              type="application/pdf"
              frameborder="0"
              width="100%"
              height="600px">
              <embed
                src={`${s3baseUrl + goalsValue?.certificate_url}#zoom=40`}
                width="100%"
                height="600px"
              />
            </object> */}
          </div>
        </Box>
      </Modal>
    </div>
  );
}
