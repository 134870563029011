import React, { useState, useEffect } from "react";
import { TextField, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import MomentUtils from "@date-io/moment";
import { useSnackbar } from "notistack";
import "react-datepicker/dist/react-datepicker.css";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import moment from "moment";
import {
  AddMemoriesApi,
  UploadMagicMomentImageOnS3,
} from "src/DAL/Memories/Memories";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

export default function AddMemories({
  onCloseDrawer,
  dataList,
  setMemoriesData,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [previews, setPreviews] = useState([]);
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    momentDescription: "",
    memoryTitle: "",
    momentDate: new Date(),
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleChangeDate = (event) => {
    setState((prevState) => {
      return {
        ...prevState,
        momentDate: event?.$d,
      };
    });
  };

  const handleRemove = (index) => {
    previews.splice(index, 1);
    files.splice(index, 1);
    setPreviews([...previews]);
    setFiles([...files]);
  };

  const handleUpload = (event) => {
    const fileList = event.target.files[0];
    const preview = URL.createObjectURL(event.target.files[0]);
    setFiles((prevFiles) => [...prevFiles, fileList]);
    setPreviews((prevPreviews) => [...prevPreviews, preview]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (state.memoryTitle.length < 5) {
      enqueueSnackbar("Moment title must be at least 5 characters long", {
        variant: "error",
      });
      setIsLoading(false);
      return;
    }
    if (state.momentDescription.length < 5) {
      enqueueSnackbar("Moment description must be at least 5 characters long", {
        variant: "error",
      });
      setIsLoading(false);
      return;
    }

    if (files.length < 1) {
      setIsLoading(false);
      enqueueSnackbar("Please select at least 1 image", { variant: "error" });
    } else {
      const results = files.map((image, index) => {
        const formData = new FormData();
        formData.append("image", image);
        const result = UploadMagicMomentImageOnS3(formData);
        return result;
      });

      Promise.all(results).then(async (img_results) => {
        let setImageArray = [];
        img_results.map((images, index) => {
          setImageArray.push(images.image_path);
        });

        var dateString = moment(state.momentDate).format("YYYY-MM-DD");
        const formDataObject = {
          moment_title: state.memoryTitle,
          moment_description: state.momentDescription,
          moment_date: dateString,
          moment_image: setImageArray,
        };

        const result = await AddMemoriesApi(JSON.stringify(formDataObject));
        if (result.code === 200) {
          const newObject = result.magicMoment;
          const memoryObject = {
            pinteres_id: newObject._id,
            pinterestImage: newObject.moment_image[0].thumbnail_2,
            memoriesImages: newObject.moment_image,
            pinterestDescription: newObject.moment_description,
            pinterestTitle: newObject.moment_title,
            pinterestDate: newObject.moment_date,
            pinterestSlug: newObject.moment_slug,
          };
          dataList.splice(0, 0, memoryObject);
          setMemoriesData(dataList);
          onCloseDrawer();
          setIsLoading(false);
          enqueueSnackbar(result.message, { variant: "success" });
        } else {
          enqueueSnackbar(result.message, { variant: "error" });
          setIsLoading(false);
        }
      });
    }
  };

  return (
    <div className="container new-memories">
      <form onSubmit={handleSubmit}>
        <TextField
          className="mt-4 inputs-fields"
          id="outlined-basic"
          label="Moment Title"
          variant="outlined"
          name="memoryTitle"
          value={state.memoryTitle}
          required={true}
          onChange={(e) => handleChange(e)}
        />

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            format="DD/MM/YYYY"
            mask="__/__/____"
            inputFormat="DD/MM/YYYY"
            label="Moment Date"
            name="momentDate"
            value={state.momentDate}
            onChange={(e) => handleChangeDate(e)}
            renderInput={(params) => (
              <TextField
                {...params}
                className="mt-3 inputs-fields"
                required={true}
              />
            )}
          />
        </LocalizationProvider>

        <TextField
          id="outlined-multiline-static"
          label="Moment Description"
          multiline
          rows={5}
          variant="outlined"
          style={{ width: "100%" }}
          name="momentDescription"
          value={state.momentDescription}
          onChange={(e) => handleChange(e)}
          className="mt-3 inputs-fields"
          required={true}
        />

        <div className="col-md-12 mt-2 d-flex">
          <div className="row w-100 add-memories-preview">
            <h4 className="mt-3 mb-0 ms-1">Upload Image*</h4>
            <p className="mt-0 mb-0 ms-1 recommended">
              Recommended Size (1000x670)
            </p>
            {previews &&
              previews.map((file, index) => (
                <div className="col-3 mt-2">
                  <span className="preview">
                    <span onClick={() => handleRemove(index)}>x</span>
                    <img className="image-border" src={file} />
                  </span>
                </div>
              ))}
            <div className="col-3 mt-2">
              <span className="upload-button">
                <input
                  color="primary"
                  accept="image/*"
                  type="file"
                  id="icon-button-file"
                  style={{ display: "none" }}
                  name="affirmationImage"
                  onChange={handleUpload}
                />
                <label htmlFor="icon-button-file" className="image-border">
                  <CloudUploadIcon />
                </label>
              </span>
            </div>
          </div>
        </div>
        <div className="text-end mt-3">
          <button className="submit-button-search" disabled={isLoading}>
            {isLoading ? "Saving..." : "Save"}
          </button>
        </div>
      </form>
    </div>
  );
}
