import { invokeApi } from "../../bl_libs/invokeApi";

export const listMeditationDailyApi = async () => {
  const requestObj = {
    path: `api/meditation/get_member_meditation_logs`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const listAllMeditationsApi = async () => {
  const requestObj = {
    path: `api/meditation/meditation_list_by_member`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const listMeditationVideos = async () => {
  const requestObj = {
    path: `api/meditation/active_meditation`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const addMeditationDailyApi = async (data) => {
  const requestObj = {
    path: `api/meditation/add_member_meditation_logs`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const updateMeditationDailyApi = async (id, data) => {
  const requestObj = {
    path: `api/meditation/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const deleteMeditationDailyApi = async (id) => {
  const requestObj = {
    path: `api/meditation/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
