import { invokeApi } from "../../bl_libs/invokeApi";

export const listGratitudeDailyApi = async (data) => {
  const requestObj = {
    path: `api/gratitude/gratitude_list_by_member_and_date`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const listAllGratitudesApi = async () => {
  const requestObj = {
    path: `api/gratitude/get_all_gratitude`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const addGratitudeDailyApi = async (data) => {
  const requestObj = {
    path: `api/gratitude/`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const updateGratitudeDailyApi = async (id, data) => {
  const requestObj = {
    path: `api/gratitude/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const deleteGratitudeDailyApi = async (id) => {
  const requestObj = {
    path: `api/gratitude/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
