import {
  CircularProgress,
  TextField,
  Card,
  CardContent,
  Box,
  IconButton,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";

import { goalSide, goalLogo } from "src/assets";
import AddGoal from "./AddGoal";
import {
  complete_goal,
  delete_goal,
  get_completed_goals,
} from "src/DAL/Goals/Goals";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DownloadIcon from "@mui/icons-material/Download";
import CustomConfirmation from "src/components/CustomConfirmation";
import PreviewGoal from "./PreviewGoal";
import { s3baseUrl } from "src/config/config";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import { LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";

import moment from "moment";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
  notchedOutline: {
    borderColor: "#000 !important",
    border: "1px solid",
  },
  loader: {
    width: "20px",
    height: "20px",
  },
}));

export default function CompletedGoals() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [goalsList, setGoalsList] = useState([]);
  const [goalsValue, setGoalsValue] = useState();
  const [openPreview, setOpenPreview] = useState(false);
  const { contentSettingData, handleGeneralSettingData } = useContentSetting();
  const handleChangeDate = (newValue) => {
    let todayDate = moment(newValue).format("YYYY-MM-DD");
    let dateType = todayDate.toString();
    //console.log(typeof dateType, "dateType");
    setDate(newValue);
  };

  const handleAgreeDelete = (value) => {
    setOpenDelete(true);
    setGoalsValue(value);
  };
  const handlePreview = (value) => {
    setGoalsValue(value);
    setOpenPreview(true);
  };
  const handleDownload = async (value) => {
    setGoalsValue(value);
    let path = "";
    setIsLoadingButton(true);
    const file_path = `${s3baseUrl}${value.certificate_url}`;
    fetch(file_path)
      .then(async (res) => {
        if (res.status === 200) {
          let blob = await res.blob();
          saveAs(blob, path);
          setIsLoadingButton(false);
        } else {
          enqueueSnackbar(res.statusText, { variant: "error" });
          setIsLoadingButton(false);
        }
      })
      .catch((err) => {
        //console.log(err);
        setIsLoadingButton(false);
      });
  };
  const getGoalsList = async () => {
    setIsLoading(true);
    const result = await get_completed_goals();
    if (result.code === 200) {
      setIsLoading(false);
      setGoalsList(result?.goals);
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const deleteGoal = async () => {
    const result = await delete_goal(goalsValue._id);
    console.log(result, "result of delete api ........");
    if (result.code === 200) {
      setGoalsList((prevRecent) =>
        prevRecent.filter((entry) => entry._id !== goalsValue._id)
      );
      handleGeneralSettingData(result?.goal_completion_coins);
      setOpenDelete(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setOpenDelete(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    getGoalsList();
  }, []);
  const formatDate = () => {
    const today = new Date();
    const day = today.getDate();
    const month = today.getMonth() + 1;
    const year = today.getFullYear().toString().slice(-2);

    return `${day}-${month}-${year}`;
  };

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="row pt-70" id="assessment-container">
        <div className="col-12">
          <IconButton
            className="back-screen-button"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
        </div>
        <div className="col-6 mb-4">
          <h2>Completed Goals</h2>
        </div>
        <div className="col-lg-6 text-end mb-2"></div>
      </div>
      {goalsList && goalsList.length > 0 ? (
        goalsList.map((data, index) => {
          console.log(data, "datadataatad");
          return (
            <div className="d-flex mb-4" key={index}>
              <div className="col-1"></div>

              <div
                className="col-10 d-flex justify-content-center  align-items-center"
                style={{}}
              >
                <Card
                  sx={{
                    backgroundColor: "#ffee00",
                    margin: "auto",
                  }}
                >
                  <div className="row">
                    <div
                      className="col-3 mt-4 mt-lg-0 align-items-center d-flex"
                      style={{ width: "20%" }}
                    >
                      <img
                        className="mt-4 mt-lg-0"
                        src={goalSide}
                        alt="Left Aligned"
                        style={{ width: "auto", height: "auto" }}
                      />
                    </div>
                    <div className="col-9 align-items-center">
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <img
                          src={goalLogo}
                          alt="Logo"
                          style={{ width: 150, height: 70 }}
                        />
                      </Box>
                      <div className="col-12 d-flex text-end justify-content-end date-div">
                        <p>
                          {/* {new Date(data.createdAt).getDate()}-
                        {new Date(data.createdAt).getMonth() + 1}-
                        {new Date(data.createdAt)
                          .getFullYear()
                          .toString()
                          .slice(-2)} */}
                        </p>{" "}
                      </div>

                      <Box className="d-flex">
                        <CardContent>
                          <TextField
                            InputProps={{
                              classes: {
                                notchedOutline: classes.notchedOutline,
                              },
                            }}
                            fullWidth
                            disabled
                            label="Class"
                            className="custom-goal-field"
                            variant="outlined"
                            margin="normal"
                            name="class"
                            value={data.class}
                          />

                          <TextField
                            disabled
                            fullWidth
                            InputProps={{
                              classes: {
                                notchedOutline: classes.notchedOutline,
                              },
                            }}
                            className="custom-goal-field"
                            label="Goal"
                            variant="outlined"
                            margin="normal"
                            name="goal"
                            value={data.goal}
                          />
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                              disabled
                              fullWidth
                              InputProps={{
                                classes: {
                                  notchedOutline: classes.notchedOutline,
                                },
                              }}
                              label="Target Date*"
                              inputFormat="dd/MM/yyyy"
                              value={moment(data.target_date).format(
                                "DD MMM YYYY"
                              )}
                              onChange={handleChangeDate}
                              renderInput={(params) => (
                                <TextField
                                  className="custom-goal-field"
                                  {...params}
                                  fullWidth
                                  sx={{
                                    marginTop: "10px",
                                    marginBottom: "4px",
                                    "& .MuiOutlinedInput-root": {
                                      "& .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "black",
                                      },
                                      "&:hover .MuiOutlinedInput-notchedOutline":
                                        {
                                          borderColor: "black",
                                        },
                                      "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                        {
                                          borderColor: "black",
                                        },
                                    },
                                    "& .MuiInputLabel-root": {
                                      color: "black",
                                    },
                                    "& .MuiInputLabel-root.Mui-focused": {
                                      color: "black",
                                    },
                                  }}
                                />
                              )}
                            />
                          </LocalizationProvider>

                          <TextField
                            disabled
                            fullWidth
                            className="custom-goal-field"
                            InputProps={{
                              classes: {
                                notchedOutline: classes.notchedOutline,
                              },
                            }}
                            label="Description"
                            variant="outlined"
                            margin="normal"
                            multiline
                            rows={3}
                            name="description"
                            value={data.description}
                          />
                        </CardContent>
                      </Box>
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-11 text-end">
                      <Tooltip title={"Delete Goal"}>
                        <IconButton
                          className="goal-icons"
                          onClick={() => handleAgreeDelete(data)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={"View Goal"}>
                        <IconButton
                          className="goal-icons"
                          onClick={() => handlePreview(data)}
                        >
                          <RemoveRedEyeIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={"Download Goal Certificate"}>
                        <IconButton
                          className="goal-icons"
                          onClick={() => handleDownload(data)}
                        >
                          {goalsValue &&
                          data._id == goalsValue?._id &&
                          isLoadingButton ? (
                            <CircularProgress size={20} />
                          ) : (
                            <DownloadIcon />
                          )}
                        </IconButton>
                      </Tooltip>
                    </div>
                    <div className="col-1"></div>
                  </div>
                </Card>
              </div>
            </div>
          );
        })
      ) : (
        <RecordNotFound title={"Completed Goals Not Found"} />
      )}

      <AddGoal
        openCreateModal={openModal}
        setOpenCreateModal={setOpenModal}
        apiCall={getGoalsList}
      />
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete Goal ?"}
        handleAgree={deleteGoal}
      />
      <PreviewGoal
        openCreateModal={openPreview}
        setOpenCreateModal={setOpenPreview}
        goalsValue={goalsValue}
      />
    </div>
  );
}
