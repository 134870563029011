import React, { useState } from "react";
import Box from "@mui/material/Box";
import { useSnackbar } from "notistack";
import Modal from "@mui/material/Modal";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Confetti from "react-confetti";
import useWindowSize from "react-use/lib/useWindowSize";
import {
  complete_challenge,
  complete_challenge_and_post,
  uploadMultipleImageOns3,
} from "src/DAL/Challenges/Challenges";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { FormControl, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { s3baseUrl } from "src/config/config";
import { uploadFeedImageOns3 } from "src/DAL/CommunityArea/Community";
import Greetings from "./Greetings";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "45%",
    marginTop: "2%",
    marginBottom: "2%",
  },
}));
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 640,
  maxWidth: "100%",
  maxHeight: "100vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: 1,
  overflow: "auto",
};

export default function Notes({
  openCreateModal,
  setOpenCreateModal,
  confettiPlay,
  setConfettiPlay,
  challengeId,
  handleRecordingsandResources,
  diaryDate,
  programmesDetail,
  completeDate,
  notesSection,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPost, setIsLoadingPost] = useState(false);
  const [openGreetingModal, setOpenGreetingModal] = useState(false);

  const { handleGeneralSettingData, socket } = useContentSetting();
  const [shortDescriptionCk, setShortDescriptionCk] = useState("");
  const { width, height } = useWindowSize();
  const { enqueueSnackbar } = useSnackbar();
  const [previews, setPreviews] = useState([]);
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setShortDescriptionCk(value);
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };
  const handleRemoveImage = (path) => {
    setPreviews((images) => {
      return images.filter((image) => image !== path);
    });
  };
  const handleUpload = async (event) => {
    const fileList = event.target.files;
    const imagesToAdd = [];

    // Check if adding images will exceed the limit of 2
    if (previews.length + fileList.length > 2) {
      // If so, add only the remaining slots up to 2
      const remainingSlots = 2 - previews.length;
      for (let i = 0; i < remainingSlots; i++) {
        imagesToAdd.push({
          path: fileList[i],
          type: "file",
        });
      }
    } else {
      // If not, add all the selected images
      for (let i = 0; i < fileList.length; i++) {
        imagesToAdd.push({
          path: fileList[i],
          type: "file",
        });
      }
    }

    setPreviews((prevFiles) => [...prevFiles, ...imagesToAdd]);
  };
  const handleClose = (submittion) => {
    setShortDescriptionCk("");
    setOpenCreateModal(false);
    setTimeout(() => {
      setConfettiPlay(false);
    }, 8000);
    if (submittion) {
      handleRecordingsandResources();
    }
  };
  const UploadImages = async (formData) => {
    const result = await uploadMultipleImageOns3(formData);
    return result.image_path;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    // if (shortDescriptionCk == "") {
    //   enqueueSnackbar("Note is Compulsory!", { variant: "error" });
    //   return;
    // }
    setIsLoading(true);
    const results = previews.map((image, index) => {
      if (image.type === "file") {
        const formData = new FormData();
        formData.append("image", image.path);
        const result = UploadImages(formData);
        return result;
      } else {
        return image.path;
      }
    });
    Promise.all(results).then(async (img_results) => {
      const formData = new FormData();

      formData.append("note", shortDescriptionCk);
      formData.append("date", diaryDate);
      formData.append("images", JSON.stringify(img_results));
      console.log(...formData, "postData");
      const result = await complete_challenge(challengeId, formData);
      if (result.code === 200) {
        setIsLoading(false);

        handleGeneralSettingData(result?.members_coins);
        // handleRecordingsandResources();
        if (result.challenge_history.is_completed) {
          console.log("called");
          setOpenGreetingModal(true);
          enqueueSnackbar(result.message, {
            variant: "success",
          });
          setOpenCreateModal(false);
        } else {
          setConfettiPlay(true);
          handleClose(true);
        }
      } else {
        setIsLoading(false);
        enqueueSnackbar(result.message, { variant: "error" });
      }
    });
  };
  const handleSubmitDashboard = async (e) => {
    e.preventDefault();
    if (shortDescriptionCk == "" && previews.length == 0) {
      enqueueSnackbar(
        "Please upload a note or image to be uploaded on the dashboard",
        { variant: "error" }
      );
      return;
    }
    setIsLoadingPost(true);
    const results = previews.map((image, index) => {
      if (image.type === "file") {
        const formData = new FormData();
        formData.append("image", image.path);
        const result = UploadImages(formData);
        return result;
      } else {
        return image.path;
      }
    });
    Promise.all(results).then(async (img_results) => {
      const formData = new FormData();

      formData.append("note", shortDescriptionCk);
      formData.append("date", diaryDate);
      formData.append("images", JSON.stringify(img_results));
      console.log(...formData, "postData");
      const result = await complete_challenge_and_post(challengeId, formData);
      if (result.code === 200) {
        socket.emit("send_notification_count", result?.receiver_members);

        setIsLoadingPost(false);

        handleGeneralSettingData(result?.members_coins);
        // handleRecordingsandResources();
        if (result.challenge_history.is_completed) {
          console.log("called");
          setOpenCreateModal(false);
          setOpenGreetingModal(true);
          enqueueSnackbar(result.message, {
            variant: "success",
          });
        } else {
          setConfettiPlay(true);
          handleClose(true);
        }
      } else {
        setIsLoadingPost(false);
        enqueueSnackbar(result.message, { variant: "error" });
      }
    });
  };

  return (
    <>
      <div>
        {confettiPlay && <Confetti width={width} height={height} />}
        <Modal
          open={openCreateModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Box sx={style} className="modal-theme">
            <div
              className="text-center modalIcon col-12 create-post-title-box"
              onClick={() => {
                handleClose();
              }}>
              <h2>Add Note</h2>
              <hr />
              <span>x</span>
            </div>

            <div className="col-12 mt-3">
              <FormControl fullWidth>
                <TextField
                  id="outlined-multiline-flexible"
                  label="Challenge Notes"
                  multiline
                  rows={5}
                  name="short_description"
                  value={shortDescriptionCk}
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                />
              </FormControl>
            </div>
            <div className="col-12 mt-2 w-100">
              <div className="row">
                {previews.length > 0 &&
                  previews.map((preview) => {
                    return (
                      <span className="col-2 mb-3 preview create-post-images notes-images">
                        <span
                          onClick={() => {
                            handleRemoveImage(preview);
                          }}>
                          x
                        </span>
                        <img
                          src={
                            preview.type == "file"
                              ? URL.createObjectURL(preview.path)
                              : s3baseUrl + preview.thumbnail_1
                          }
                        />
                      </span>
                    );
                  })}
              </div>

              <div className="row">
                <div className="col-12">
                  {previews.length < 2 && (
                    <span className="upload-button-notes mt-2">
                      <input
                        multiple
                        color="primary"
                        accept="image/*"
                        type="file"
                        id="icon-button-file"
                        style={{ display: "none" }}
                        onChange={handleUpload}
                      />
                      <label htmlFor="icon-button-file">
                        {/* <span>x</span> */}
                        <p className="mt-3">
                          Add Photo <br />
                          <CloudUploadIcon />
                        </p>
                      </label>
                      <p className="max-images">
                        Maximum 2 images are allowed per upload.
                      </p>
                    </span>
                  )}
                </div>
              </div>
            </div>

            <div className="col-12 text-center mt-3 notes-buttons">
              <button
                className="small-contained-button-notes post-submit-btn"
                onClick={handleSubmit}
                disabled={isLoading || isLoadingPost}>
                {isLoading ? "Saving..." : "Mark As Complete"}
              </button>
              {programmesDetail?.is_published_on_community && (
                <button
                  className="small-contained-button-notes post-submit-btn"
                  disabled={isLoading || isLoadingPost}
                  onClick={handleSubmitDashboard}>
                  {isLoadingPost
                    ? "Posting..."
                    : "Mark As Complete & Share To Dashboard"}
                </button>
              )}
            </div>
          </Box>
        </Modal>
      </div>
      <Greetings
        openCreateModal={openGreetingModal}
        setOpenCreateModal={setOpenGreetingModal}
        setConfettiPlay={setConfettiPlay}
        handleRecordingsandResources={handleRecordingsandResources}
        completeDate={completeDate}
      />
    </>
  );
}
