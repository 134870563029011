import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Divider, Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import {
  csvImage,
  docImage,
  pdfImage,
  wordImage,
  audioImage,
  xlsxImage,
  otherImage,
} from "src/assets";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import ResourcesCard from "src/components/_dashboard/programmes/ResourcesCard";
import ProgrammRecording from "src/components/_dashboard/programmes/ProgrammeRecording";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";
import ImageBox from "src/components/ModelBox/ImageBox";

import ChallengeResourceCard from "src/components/Challenges/ChallengeResourceCard";
import ChallengeRecording from "src/components/Challenges/ChallengeRecording";
import GoalGallerySlider from "../GoalStatement/GoalGallerySlider";
import { s3baseUrl } from "src/config/config";
import { useState } from "react";
import moment from "moment";
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"} variant={"body2"}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function ChallengeDetailTabs({
  resources,
  recordingList,
  programSlug,
  programmesDetail,
  notesSection,
}) {
  const [sliderData, setSliderData] = React.useState([]);
  const [imageUrl, setImageUrl] = useState("");
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
    setImageUrl("");
  };
  React.useEffect(() => {
    if (notesSection && notesSection.images && notesSection.images.length > 0) {
      let galleryArray = [];
      let galleryObject = {};
      notesSection.images.map((gallery, index) => {
        galleryObject = {
          original: s3baseUrl + gallery.thumbnail_1,
          thumbnail: s3baseUrl + gallery.thumbnail_2,
        };
        galleryArray.push(galleryObject);
      });
      setSliderData(galleryArray);
    }
  }, []);
  const [value, setValue] = React.useState(0);

  const imageLinks = {
    docx: wordImage,
    mp3: audioImage,
    pdf: pdfImage,
    csv: csvImage,
    doc: docImage,
    xlsx: xlsxImage,
    xls: xlsxImage,
    other: otherImage,
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleOpen = (image_path) => {
    setImageUrl(image_path);
    setTimeout(() => {
      setOpen(true);
    }, 100);
  };
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "#c2c2c2" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          // allowScrollButtonsMobile="auto"
          aria-label="scrollable auto tabs example">
          <Tab label="Challenge" {...a11yProps(0)} sx={{ color: "#000" }} />
          <Tab label="Resources" {...a11yProps(1)} sx={{ color: "#000" }} />
          <Tab label="Recordings" {...a11yProps(2)} sx={{ color: "#000" }} />
          {notesSection && notesSection.length > 0 && (
            // {notesSection && notesSection.is_completed == true && (
            <Tab label="Notes" {...a11yProps(3)} sx={{ color: "#000" }} />
          )}
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <div className="col-12 section-space set-image-center">
          <div
            dangerouslySetInnerHTML={{
              __html: programmesDetail?.detail_description,
            }}></div>
        </div>
      </TabPanel>

      <TabPanel value={value} index={1}>
        <div className="row">
          {resources.length > 0 ? (
            resources.map((resource) => (
              <div
                className="col-lg-6 col-md-6 col-sm-12 mt-3 d-flex"
                key={resource._id}>
                <ChallengeResourceCard
                  imageLink={imageLinks}
                  resource={resource}
                />
              </div>
            ))
          ) : (
            <RecordNotFound title="Resources Not Found" />
          )}
        </div>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <div className="row">
          {recordingList.length > 0 ? (
            recordingList.map((recording, i) => (
              <div className="col-12 mt-3" key={recording._id}>
                <ChallengeRecording
                  programSlug={programSlug}
                  recording={recording}
                />
              </div>
            ))
          ) : (
            <RecordNotFound title="Recordings Not Found" />
          )}
        </div>
      </TabPanel>
      {notesSection && (
        // {notesSection && notesSection.is_completed == true && (
        <TabPanel value={value} index={3}>
          {notesSection.length > 0 ? (
            <>
              <div className="row">
                <div className="col-12">
                  {notesSection?.map((data, index) => {
                    console.log(data, "dataaaaaaaaaaaa");
                    return (
                      <>
                        {data.images.length > 0 || data.note ? (
                          <div key={index} className="team-member mt-2">
                            <div sx={{ my: 3, mx: 2 }}>
                              <Grid container alignItems="center">
                                <Grid>
                                  <p className="note-date mb-2">
                                    {moment(data.date).format("YYYY-MM-DD")}
                                  </p>
                                  <Typography
                                    gutterBottom
                                    variant="h6"
                                    component="div"
                                    className="text-color-cards mb-3">
                                    {data.note}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <div></div>
                              {data.images.length > 0 && (
                                <div className="d-flex">
                                  {data.images?.map((path, i) => {
                                    return (
                                      <div>
                                        <img
                                          key={i}
                                          src={s3baseUrl + path?.thumbnail_1}
                                          alt="comment image"
                                          onClick={() => {
                                            handleOpen(
                                              s3baseUrl + path?.thumbnail_2
                                            );
                                          }}
                                        />
                                      </div>
                                    );
                                  })}
                                </div>
                              )}
                            </div>
                            <Divider className="divider-mui" variant="middle" />
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    );
                  })}
                </div>
              </div>
            </>
          ) : (
            <RecordNotFound title={"Notes Not Found"} />
          )}
        </TabPanel>
      )}
      <ImageBox open={open} handleClose={handleClose} image_url={imageUrl} />
    </Box>
  );
}
