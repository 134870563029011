import React from "react";
import { s3baseUrl } from "src/config/config";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { uploadVisionImage } from "src/DAL/VisionBoard/VisionBoard";
import { CircularProgress } from "@mui/material";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "45%",
    marginTop: "2%",
    marginBottom: "2%",
  },
}));
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 480,
  maxWidth: "100%",
  maxHeight: "100vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: 1,
  overflow: "auto",
};
export default function VisionImage({
  previewImages,
  setPreviewImages,
  openCreateModal,
  setOpenCreateModal,
}) {
  const classes = useStyles();
  const [previews, setPreviews] = useState([]);
  const [image, setImage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    setIsLoading(false);
    setOpenCreateModal(false);

    setPreviews([]);
    setImage("");
  };

  const handleUpload = async (event) => {
    const fileList = event.target.files;
    const imagesToAdd = [];

    // Check if adding images will exceed the limit of 1
    if (previews.length + fileList.length > 1) {
      // If so, add only the remaining slots up to 1
      const remainingSlots = 1 - previews.length;
      for (let i = 0; i < remainingSlots; i++) {
        imagesToAdd.push({
          path: fileList[i],
          type: "file",
        });
      }
    } else {
      // If not, add all the selected images
      for (let i = 0; i < fileList.length; i++) {
        imagesToAdd.push({
          path: fileList[i],
          type: "file",
        });
      }
    }

    setPreviews((prevFiles) => [...prevFiles, ...imagesToAdd]);
  };
  const handleCreatePost = async (e) => {
    e.preventDefault();
    if (!image && previews.length < 1) {
      enqueueSnackbar("Please add image to be uploaded", {
        variant: "error",
      });
      return;
    }
    setIsLoading(true);
    const formData = new FormData();
    formData.append("image", previews[0].path);
    formData.append("x_axis", 0);
    formData.append("y_axis", 0);
    console.log(previews, "previews");
    console.log(...formData, "formData");
    const result = await uploadVisionImage(formData);
    if (result.code === 200) {
      setPreviewImages((prevImages) => {
        if (Array.isArray(prevImages)) {
          return [...prevImages, result.image_path];
        } else {
          return [result.image_path];
        }
      });

      // setPreviewImages((prevImages) => [...prevImages, result.image_path]);
      setIsLoading(false);
      enqueueSnackbar("Image Uploaded Successfully", {
        variant: "success",
      });
    } else {
      enqueueSnackbar(result.message, {
        variant: "error",
      });
      setIsLoading(false);
    }
    handleClose();
  };
  const handleRemoveImage = (path) => {
    setPreviews((images) => {
      return images.filter((image) => image !== path);
    });
  };
  return (
    <div>
      <Modal
        open={openCreateModal}
        onClose={handleClose}
        BackdropProps={{ onClick: (e) => e.stopPropagation }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <form className="create-post-title-form" onSubmit={handleCreatePost}>
          <Box sx={style} className="modal-theme">
            <div className="text-center col-12 create-post-title-box">
              <h2>Upload Image</h2>
              <hr />
              <span
                onClick={() => {
                  handleClose();
                }}>
                x
              </span>
            </div>

            <div className="col-md-12 mt-2">
              <div className="row">
                {previews.length > 0 &&
                  previews.map((preview) => {
                    return (
                      <span className="col-3 mb-3 preview create-post-images">
                        <span
                          onClick={() => {
                            handleRemoveImage(preview);
                          }}>
                          x
                        </span>
                        <img
                          src={
                            preview.type == "file"
                              ? URL.createObjectURL(preview.path)
                              : ""
                          }
                          alt="no image"
                        />
                      </span>
                    );
                  })}
              </div>

              <div className="row">
                <div className={`col-12`}>
                  {previews.length < 1 && (
                    <span className="upload-button mt-2 w-100">
                      <input
                        multiple
                        color="primary"
                        accept="image/*"
                        type="file"
                        id="icon-button-file"
                        style={{ display: "none" }}
                        onChange={handleUpload}
                      />
                      <label htmlFor="icon-button-file">
                        <p className="mt-3">
                          Upload Image <br />
                          <CloudUploadIcon />
                        </p>
                      </label>
                    </span>
                  )}
                </div>
              </div>
            </div>

            <div className="col-12 text-center mt-2">
              <button
                className="small-contained-button post-submit-btn"
                disabled={isLoading}>
                {isLoading ? (
                  <>
                    <CircularProgress
                      className="loading-preview-svg"
                      size={14}
                      color="inherit"
                      sx={{ position: "relative", top: "2px", right: "10px" }}
                    />
                    Uploading...
                  </>
                ) : (
                  "Upload"
                )}
              </button>
            </div>
          </Box>
        </form>
      </Modal>
    </div>
  );
}
