import { invokeApi } from "../../bl_libs/invokeApi";

export const meditation_challenge_list_api = async (value) => {
  const requestObj = {
    path: `api/challenge/challenge_list_for_member_v1?type=${
      value ? value : "mediation"
    }`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const challenge_member_list_api = async (id, page, limit) => {
  const requestObj = {
    path: `api/challenge/list_people_doing/${id}?page=${page}&limit=${limit}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const get_challange_history_by_member_api = async (data) => {
  const requestObj = {
    path: `api/member/get_challange_history_by_member`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const update_challange_history_by_member_meditation_api = async (
  data
) => {
  const requestObj = {
    path: `api/member/update_meditation_challange_history_by_member`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const list_challenge_member = async (id) => {
  const requestObj = {
    path: `api/challenges/active_challenges`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const accept_challenge = async (id) => {
  const requestObj = {
    path: `api/challenges/accept_challenge/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const complete_challenge = async (id, data) => {
  const requestObj = {
    path: `api/challenges/complete_challenge/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const complete_challenge_and_post = async (id, data) => {
  const requestObj = {
    path: `api/challenges/complete_and_post_challenge/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const challenges_detail_api = async (id) => {
  const requestObj = {
    path: `api/challenges/detail_challenge/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const challenges_resources_api = async (id) => {
  const requestObj = {
    path: `api/challenges/challenge_resources_and_recordings/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const uploadMultipleImageOns3 = async (data) => {
  const requestObj = {
    path: `app/upload_image`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
