import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { IconButton, Chip } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { htmlDecode } from "src/utils/convertHtml";
import ReactVideoPlayerDashboard from "../ReactPlayers/ReactVideoPlayerDashboard";

function ChallengeRecordingDetail(props) {
  const navigate = useNavigate();
  const { state } = useLocation();
  const handleNavigate = () => {
    navigate(-1);
  };

  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <IconButton
            className="back-screen-button"
            onClick={() => handleNavigate()}>
            <ArrowBackIcon />
          </IconButton>
        </div>
      </div>

      <div className="row section-space">
        <div className="col-12">
          <h1 className="programmes-heading">{htmlDecode(state.title)}</h1>
        </div>
      </div>
      <div className="row ">
        <section className="container top-section-video mb-lg-3">
          <div className="row media-margin">
            <div className="col-12">
              <ReactVideoPlayerDashboard url={state.video_url} />
            </div>
          </div>
        </section>
      </div>

      <div className="col-12 section-space">
        <p className="description-style">{htmlDecode(state.description)}</p>
      </div>
    </div>
  );
}

export default ChallengeRecordingDetail;
