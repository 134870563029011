import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { IconButton, CircularProgress, Chip } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ReactVideoDurationPlayer } from "src/components";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { htmlDecode } from "src/utils/convertHtml";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { s3baseUrl } from "src/config/config";
import { GettingStartedRecordingDetailApi } from "src/DAL/Programmes/Programmes";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
function GettingStartedRecordingdetail(props) {
  const params = useParams();
  const location = useLocation();
  const { recording } = location;
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [recordingData, seRecordingData] = React.useState({});
  const { handleVideoList } = useContentSetting();
  const [videoProgressData, setVideoProgressData] = useState([]);
  console.log(recordingData, "recording data.........");
  console.log(props, "propssssssss");
  const handleNavigate = () => {
    // navigate(`/getting-started-recordings/${recordingData.recording_slug}`);
    navigate(-1);
  };
  console.log(recording, "...............");
  const handleNavigateLink = (value) => {
    window.open(value, "_blank");
  };
  console.log(recordingData, "dtaatattattat");

  const getRecordingDetail = async () => {
    setIsLoading(true);
    let result = await GettingStartedRecordingDetailApi(params.recording_slug);
    console.log(result, "..........");

    if (result.code === 200) {
      let data = [
        {
          recording_id: result.recording?._id,
          video_duration: result.recording?.video_duration,
          total_video_duration: result.recording?.total_video_duration,
          is_complete: result.recording?.is_complete,
        },
      ];
      setVideoProgressData(data);
      handleVideoList(data);
      localStorage.setItem("video_list", JSON.stringify(data));

      seRecordingData(result.recording);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    getRecordingDetail();
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <IconButton className="back-screen-button" onClick={handleNavigate}>
            <ArrowBackIcon />
          </IconButton>
        </div>
      </div>

      <div className="row section-space">
        <div className="col-12">
          <h1 className="programmes-heading">
            {htmlDecode(recordingData.title)}
          </h1>
        </div>
      </div>
      <div className="row ">
        <section className="container top-section-video mb-lg-3">
          <div className="row pt-70 mt-3">
            <div className="col-2"></div>
            <div className="col-8">
              <ReactVideoDurationPlayer
                url={recordingData.video_url}
                type={localStorage.getItem("video_type")}
                value={recordingData}
                setVideoProgressData={setVideoProgressData}
                videoProgressData={videoProgressData}
              />
            </div>
            <div className="col-2"></div>
          </div>
        </section>
        <div className="col-12 text-end">
          {recordingData.is_complete ? <></> : <></>}
        </div>
        {recordingData.audio_recording && (
          <div className="col-12 mt-3 text-center">
            <div>
              <audio
                controlsList="nodownload"
                className="w-100"
                src={s3baseUrl + recordingData.audio_recording}
                controls
              />
            </div>
          </div>
        )}
        <div className="col-12 section-space text-end">
          {recordingData?.button_text && recordingData?.button_link && (
            <Chip
              label={htmlDecode(recordingData?.button_text)}
              color="primary"
              className="me-1"
              variant="outlined"
              onClick={() => handleNavigateLink(recordingData?.button_link)}
            />
          )}
        </div>
        <div className="col-12 section-space">
          <p className="description-style">
            {htmlDecode(recordingData.short_description)}
          </p>
        </div>
      </div>
    </div>
  );
}

export default GettingStartedRecordingdetail;
