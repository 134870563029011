import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import RatingCard from "./RatingCard";
import ProgrammRecording from "./ProgrammeRecording";
import LessonsCard from "./LessonsCard";
import StudySessionCard from "./StudySessionCard";
import ResourcesCard from "./ResourcesCard";
import { ReactVideoDurationPlayer } from "src/components";
import { NoAccess, RecordNotFound } from ".";
import {
  csvImage,
  docImage,
  pdfImage,
  wordImage,
  audioImage,
  xlsxImage,
  otherImage,
} from "src/assets";
import { htmlDecode } from "src/utils/convertHtml";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { Icon } from "@iconify/react";
import GettingStartedCard from "./gettingStartedCard";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useState } from "react";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"} variant={"body2"}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ProgrammDetailTab({
  lessons,
  resources,
  reviews,
  recordingList,
  programSection,
  programLockStatus,
  programSlug,
  studySession,
  programGettingStarted,
}) {
  const location = useLocation();
  const [value, setValue] = React.useState(0);
  const { contentSettingData, handleVideoList } = useContentSetting();
  const [videoProgressData, setVideoProgressData] = React.useState([]);
  const { selectedTabFromProp } = location.state || {};

  console.log(
    programGettingStarted,
    "programGettingStartedprogramGettingStartedprogramGettingStarted"
  );
  const [currentModule, setCurrentModule] = useState(location.pathname);
  console.log(currentModule, "currentModule");
  const [selectedTab, setSelectedTab] = useState(() => {});

  const handleTabChange = (event, newValue) => {
    console.log(newValue, "newvaluenewvalue...............");
    setSelectedTab(newValue);
  };

  const imageLinks = {
    docx: wordImage,
    mp3: audioImage,
    pdf: pdfImage,
    csv: csvImage,
    doc: docImage,
    xlsx: xlsxImage,
    xls: xlsxImage,
    other: otherImage,
  };
  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  React.useEffect(() => {
    let data = programSection.map((records) => {
      return {
        recording_id: records._id,
        video_duration: records.video_duration,
        total_video_duration: records.total_video_duration,
        is_complete: records.is_complete,
      };
    });
    setVideoProgressData(data);
    handleVideoList(data);
  }, [programSection]);

  useEffect(() => {
    if (selectedTabFromProp !== undefined) {
      setSelectedTab(selectedTabFromProp);
    } else {
      setSelectedTab(0);
    }
  }, [location.state]);

  useEffect(() => {
    if (location.pathname !== currentModule) {
      setSelectedTab(0);
      setCurrentModule(location.pathname);
    }
  }, [location.pathname, currentModule]);

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "#c2c2c2" }}>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          {/* Dynamic Program Sections Tabs */}
          {programSection &&
            programSection.map((programSection, index) => (
              <Tab
                key={programSection._id}
                label={htmlDecode(programSection.title)}
                {...a11yProps(index)}
                sx={{ color: "#000" }}
              />
            ))}

          {/* Static Tabs */}
          <Tab
            label="Getting Started"
            {...a11yProps(programSection.length)}
            sx={{ color: "#000" }}
          />
          <Tab
            label="Lessons"
            {...a11yProps(programSection.length + 1)}
            sx={{ color: "#000" }}
          />
          {/* <Tab
            label="Teachers Guide"
            {...a11yProps(programSection.length + 2)}
            sx={{ color: "#000" }}
          /> */}
          <Tab
            label="Resources"
            {...a11yProps(programSection.length + 3)}
            sx={{ color: "#000" }}
          />
          {studySession.length > 0 && (
            <Tab
              label="Study Session"
              {...a11yProps(programSection.length + 4)}
              sx={{ color: "#000" }}
            />
          )}
          {/* <Tab
            label="Reviews"
            {...a11yProps(programSection.length + 4)}
            sx={{ color: "#000" }}
          /> */}
        </Tabs>
      </Box>

      {programSection &&
        programSection.map((programSection, sectionIndex) => (
          <TabPanel
            value={selectedTab}
            index={sectionIndex}
            key={programSection._id}
          >
            <div className="row mt-3 program-sections">
              {programSection.video_url && (
                <>
                  <div className="col-12">
                    <ReactVideoDurationPlayer
                      url={programSection.video_url}
                      type={"program_section"}
                      value={programSection}
                      setVideoProgressData={setVideoProgressData}
                      videoProgressData={videoProgressData}
                    />
                  </div>
                  <div className="col-12 text-end">
                    {programSection.is_complete ? <></> : <></>}
                  </div>
                </>
              )}
              <div
                className="col-12 text-left section-space"
                style={{ backgroundColor: "white" }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: programSection.detailed_description,
                  }}
                ></div>
              </div>
              {programSection.button_text && (
                <div className="text-end">
                  <a target="_blank" href={programSection.button_url}>
                    <button
                      className={`small-contained-button ${
                        programSection.button_url === "" ? "disabled" : ""
                      }`}
                      disabled={programSection.button_url === "" ? true : false}
                    >
                      {htmlDecode(programSection.button_text)}
                    </button>
                  </a>
                </div>
              )}
            </div>
          </TabPanel>
        ))}
      <TabPanel value={selectedTab} index={programSection.length}>
        {programLockStatus === false ? (
          <div className="row">
            {lessons.length > 0 ? (
              programGettingStarted.map((lesson) => (
                <div className="col-12 mt-3" key={lesson._id}>
                  <GettingStartedCard
                    programLockStatus={programLockStatus}
                    programSlug={programSlug}
                    lesson={lesson}
                    selectedTabFromProp={selectedTab}
                  />
                </div>
              ))
            ) : (
              <RecordNotFound title="Getting Started" />
            )}
          </div>
        ) : (
          <NoAccess title="Getting Started" />
        )}
      </TabPanel>
      <TabPanel value={selectedTab} index={programSection.length + 1}>
        <div className="row">
          {lessons.length > 0 ? (
            lessons.map((lesson) => (
              <div className="col-12 mt-3" key={lesson._id}>
                <LessonsCard
                  programLockStatus={programLockStatus}
                  programSlug={programSlug}
                  lesson={lesson}
                  selectedTabFromProp={selectedTab}
                />
              </div>
            ))
          ) : (
            <RecordNotFound title="Lessons" />
          )}
        </div>
      </TabPanel>

      {/* <TabPanel value={selectedTab} index={programSection.length + 2}>
        {programLockStatus === false ? (
          <div className="row">
            {recordingList.length > 0 ? (
              recordingList.map((recording) => (
                <div className="col-12 mt-3" key={recording._id}>
                  <ProgrammRecording
                    programSlug={programSlug}
                    recording={recording}
                    selectedTabFromProp={selectedTab}
                  />
                </div>
              ))
            ) : (
              <RecordNotFound title="Teachers Guide" />
            )}
          </div>
        ) : (
          <NoAccess title="Teachers Guide" />
        )}
      </TabPanel> */}

      <TabPanel value={selectedTab} index={programSection.length + 2}>
        {programLockStatus === false ? (
          <div className="row">
            {resources.length > 0 ? (
              resources.map((resource) => (
                <div
                  className="col-lg-6 col-md-6 col-sm-12 mt-3 d-flex"
                  key={resource._id}
                >
                  <ResourcesCard imageLink={imageLinks} resource={resource} />
                </div>
              ))
            ) : (
              <RecordNotFound title="Resource" />
            )}
          </div>
        ) : (
          <NoAccess title="Resource" />
        )}
      </TabPanel>

      {studySession.length > 0 && (
        <TabPanel value={selectedTab} index={programSection.length + 3}>
          <div className="row">
            {studySession.map((study_session) => (
              <div className="col-12 mt-3" key={study_session._id}>
                <StudySessionCard
                  imageLink={imageLinks}
                  study_session_slug={study_session.study_session_slug}
                  studySession={study_session}
                />
              </div>
            ))}
          </div>
        </TabPanel>
      )}

      <TabPanel value={selectedTab} index={programSection.length + 4}>
        <div className="row mt-3">
          {/* {reviews.length > 0 ? (
            reviews.map((review, i) => (
              <div
                className="col-lg-4 col-md-6 col-sm-12 mt-4 d-flex"
                key={review._id}
              >
                <RatingCard review={review} reviews={reviews} />
              </div>
            ))
          ) : (
            <RecordNotFound title="Reviews" />
          )} */}
        </div>
      </TabPanel>
    </Box>
  );
}
